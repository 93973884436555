import "./skillpilllist.css";
import PropTypes from "prop-types";

function SkillPillList({ skills }) {
  return (
    <ul class="skill-pill-list">
      {skills.map((skill, index) => (
        <li className="skill-pill-list-item">{skill}</li>
      ))}
    </ul>
  );
}
SkillPillList.propTypes = {
  title: PropTypes.string.isRequired,
};

export default SkillPillList;
