import React from "react";
import PortfolioPageHeader from "../components/PortfolioPageHeader";
import headerBackground from "../assets/KB_Portfolio.png";
import ProblemSolutionCard from "../components/ProblemSolutionCard";

export default function LoanApplication() {
  let problemsSolutionsList = [
    {
      problem: "Current application is not mobile or tablet friendly.",
      solution:
        "Introduce responsive design elements to the application so that it looks good on all screen sizes.",
    },
    {
      problem: "Customers don't know the up front requirements to apply.",
      solution:
        "Communicate this information to the customer before they begin the application.",
    },
    {
      problem:
        "Customers feel that it is not clear where they are at in the application process.",
      solution:
        "Implement a progress tracker in the application itself. Implement status emails to keep the applicant informed after they've submitted.",
    },
    {
      problem:
        "Application styling and tone is not consistent with current brand guidelines.",
      solution:
        "Update the application styling to match the current brand guidelines.",
    },
    {
      problem:
        "Application asks for things that aren't actually used by underwriting in many cases.",
      solution:
        "Use logic to figure out what is actually required and only ask for that.",
    },
    {
      problem:
        "Manual documentation request process causes delays in applicants figuring out if they've been approved.",
      solution:
        "Automate the documentation determination process and ask for this information up front.",
    },
  ];

  return (
    <main>
      <PortfolioPageHeader
        title="Loan Application"
        backgroundColor="var(--dark-blue)"
        backgroundImage={headerBackground}
      />
      <div className="container">
        <div className="row mb-5">
          <div className="col-12 col-md-10 col-lg-8">
            <h2>Overview</h2>
            <p>
              This project was completed for a Tier 1 Bank to improve their loan
              application process and allow for faster decisioning and a better
              customer and employee experience. The client hired our firm to do
              an analysis of their existing business processes and customer
              experience and to address any pain points with a new design.
            </p>
            <p>
              The client wanted to explore how low-code/no-code platforms would
              enable their business to evolve their digital products more
              quickly than traditional development. We chose to use Unqork to
              deliver this project.
            </p>
          </div>
          <div className="col-12 col-md-12 col-lg-4 mt-4 mt-lg-0 ps-lg-5">
            <h2>Role</h2>
            <div className="project-role">
              <strong>Senior Product Designer</strong>
              <br />
              Business Analysis, Visual Design, Prototyping, Developer
              <br />
              <div className="mt-3">Aug 2021 - Dec 2021</div>
            </div>
          </div>
        </div>
        <div className="row mb-5">
          <div className="col-12 col-md-10 col-lg-8">
            <h2>Background</h2>
            <p>
              This project was broken out into a discovery, design, and a
              development phase. I joined the project at the end of the
              discovery phase.
            </p>
            <p>
              Our core team consisted of an account executive, a PM, a BA's, two
              low-code/no-code developers and a lead designer (me). The original
              plan had me on the poject for two weeks to plan and run a design
              jam session and create a high-fidelity prototype. However, after
              my initial design work was completed I was asked to stay on to
              manage and assist the development team for the duration of the
              project.
            </p>
            <p>Our team had several key achievements:</p>
            <ul>
              <li>
                <strong>Ran a Design Jam Session.</strong> I kicked off the
                design phase of the project by running a design jam session with
                a group of people that have deep knowledge of the application
                process.
              </li>
              <li>
                <strong>Improved the application flow.</strong> We broke up the
                application into smaller bite-sized pieces and we added logic to
                ensure that users were only asked relevant questions.
              </li>
              <li>
                <strong>Updated the application style.</strong> I worked closely
                with the client UX and marketing team to understand their visual
                language and brand, and applied those elements to the
                application.
              </li>
              <li>
                <strong>Automated the document request flow.</strong> We were
                able to map out the existing manual process for requesting
                customer documents and automate it so that it could be
                integrated into the application experience.
              </li>
              <li>
                <strong>Proved the value of low-code/no-code platforms.</strong>{" "}
                In ten weeks our three person development team created an
                end-to-end application using Unqork.
              </li>
            </ul>
          </div>
        </div>
        <div className="row mb-4">
          <div className="col-12 col-md-10 col-lg-8">
            <h2>Understanding the Opportunities</h2>
            <p>
              I planned and co-facilitated a design jam session with 20+
              participants across all of the business units (customer service,
              IT, physical bank locations, etc.) that interact with either the
              application itself or with the customer as they experience the
              application process.
            </p>
            <p>
              Our goal for this session was to identify the the improvements we
              could make to both the customer and the employee experience as the
              customers go through the loan application process.
            </p>
          </div>
        </div>
        <div className="row mb-section">
          <div className="col-12 col-md-12 col-lg-8">
            <h4 className="typography-work-samples-text">Design Jam Session</h4>
            <div className="screenshots">
              <img
                width="100%"
                src={require("../assets/kb_designjam.png")}
                alt="design jam"
              />
            </div>
          </div>
        </div>
        <div className="row mb-section">
          <div className="col-12 col-md-10 col-lg-8">
            <h2>Problems and Solutions</h2>
            <p className="mb-4">
              We took the output of the design jam and grouped the information
              based on similar themes. From this we created a list of problems
              and solutions that the MVP must address to be considered a
              success.
            </p>

            {problemsSolutionsList.map((pair, index) => (
              <div
                className={`row ${
                  index < problemsSolutionsList.length - 1
                    ? "border-bottom"
                    : ""
                }`}
              >
                <div className="col-12 col-md-6">
                  <ProblemSolutionCard
                    key={`problem-${index}`}
                    variant="problem"
                    description={pair.problem}
                  ></ProblemSolutionCard>
                </div>
                <div className="col-12 col-md-6">
                  <ProblemSolutionCard
                    key={`solution-${index}`}
                    variant="solution"
                    description={pair.solution}
                  ></ProblemSolutionCard>
                </div>
              </div>
            ))}
          </div>
        </div>

        <div className="row mb-4">
          <div className="col-12 col-md-10 col-lg-8">
            <h2>Determining the Flow</h2>
            <p>
              Following the design jam session our team pulled together an
              overview of what the ideal application flow would look like.
            </p>
            <p className="mb-4">
              After we got executive buy-in on the high-level flow we went to
              work understanding all of the technical integrations that would be
              needed to make our vision come to fruition. Following many SME
              interviews we landed on a service design blueprint that would
              allow us to address all of the identified painpoints AND do no
              harm to the existing business operations of our client.
            </p>
            <h4 className="typography-work-samples-text">High Level Flow</h4>
            <div className="screenshots">
              <img
                width="100%"
                src={require("../assets/loanapplicationflow.png")}
                className="p-2"
                alt="Loan application flow"
              />
            </div>
          </div>
        </div>

        <div className="row mb-4">
          <div className="col-12 col-md-10 col-lg-8">
            <h2>Creating the Prototype</h2>
            <p>
              Once I had a solid understanding of what I needed to design, I
              went to work prototyping the application in Figma. Luckily the
              client had recently established a Figma design system so I was
              able to leverage many of their existing components.{" "}
            </p>
          </div>
        </div>
        <div className="row mb-section">
          <div className="col-12 col-md-10">
            <iframe
              title="Loan Application Prototype"
              style={{ border: "1px solid rgba(0, 0, 0, 0.1)" }}
              width="100%"
              height="600"
              src="https://www.figma.com/embed?embed_host=share&url=https%3A%2F%2Fwww.figma.com%2Fproto%2FWm6KipcAh2COpWG9VTEl8b%2FLoan-Application%3Fnode-id%3D8%253A17965%26scaling%3Dscale-down-width%26page-id%3D0%253A1%26starting-point-node-id%3D8%253A17965"
              allowFullScreen={true}
            ></iframe>
          </div>
        </div>
        <div className="row mb-section">
          <div className="col-12 col-md-10 col-lg-8">
            <h2>Developing the MVP</h2>
            <p>
              I worked with a small three person team to develop the MVP using
              the Unqork platform. Unqork is a low-code/no-code platform that
              has a drag and drop interface for creating complex systems. That
              being said, the CSS still needed to be highly customized to match
              the client's brand guidelines, which was one of my major tasks
              during the development phase.
            </p>
          </div>
        </div>
        <div className="row mb-5">
          <div className="col-12 col-md-10 col-lg-8">
            <h2>Results</h2>
            <p>
              By the end of this initiative my team delivered a low-code/no-code
              POC that satisfied all of the major customer and employee concerns
              that were identified during our research. Up until this project I
              had never heard of low-code/no-code so it gave me a great
              opportunity to learn an extremely powerful new technology that I
              believe will be a game changer in the years to come.
            </p>
            <p>
              This project also provided a great opportunity for additional
              practice in designing within the constraints of a specific
              component library.
            </p>
          </div>
        </div>
      </div>
    </main>
  );
}
