import FeaturedWorkCard from "./FeaturedWorkCard";
import "./featuredworksection.css";

export default function IntroHeader() {
  let FeaturedWorks = [
    {
      title: "Advisor Site",
      description:
        "Total redesign of a global asset management firm's institutional investor experience",
      image: require("../assets/AB_Portfolio.png"),
      bgColor: "var(--orange)",
      textColor: "#FFFFFF",
      goTo: "fasite",
    },
    {
      title: "Loan Application",
      description:
        "Designing and building a new loan application process using a low-code platform",
      image: require("../assets/KB_Portfolio.png"),
      bgColor: "var(--dark-blue)",
      textColor: "#FFFFFF",
      goTo: "loanapplication",
    },
    {
      title: "Thanos Snap",
      description:
        "Leveraging AI based technologies to push the boundaries of what can be done in a browser",
      image: require("../assets/infinitygaunlet.png"),
      bgColor: "#573d76",
      textColor: "#FFFFFF",
      goTo: "thanossnap",
    },
  ];

  return (
    <div id="featuredWork" className="container mt-5">
      <h2 className="featured-work-title">Featured Work</h2>
      {FeaturedWorks.map((experience, index) => (
        <FeaturedWorkCard
          key={index}
          title={experience.title}
          description={experience.description}
          image={experience.image}
          bgColor={experience.bgColor}
          textColor={experience.textColor}
          goTo={experience.goTo}
        />
      ))}
    </div>
  );
}
