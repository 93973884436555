import SkillPillList from "../components/SkillPillList";
import WorkExperience from "../components/WorkExperience";

export default function About() {
  const workExperiences = [
    {
      title: "Capco",
      subtitle: "Senior Digital Consultant",
      timeRange: "2021 - Present",
      description:
        "Provide user research, UX/UI design, and development services to clients in the financial sector. The breakdown of tasks (development vs. design) depends entirely on the needs of a given client.",
    },
    {
      title: "Wandera",
      subtitle: "Freelance Full Stack Development",
      timeRange: "2020",
      description:
        "Designed and developed two customer facing interactive quiz experiences that were leveraged by the sales team to generate leads. Used the MEAN stack to develop the systems.",
    },
    {
      title: "Deloitte Digital",
      subtitle: "Design & Front End Development Lead",
      timeRange: "2018 - 2021",
      description:
        "Lead the design piece of a large digital transformation project. 50% of my time was spent leading my team to create wireframes and prototypes, and user testing for features that were prioritized for an upcoming sprint. 50% of my time was spent maintaining the design system, developing code components for the development team, and doing front-end code reviews to ensure proper usage of components.",
    },
    {
      title: "CAI",
      subtitle: "Project Manager & Business Analyst",
      timeRange: "2015 - 2018",
      description:
        "Partnered with businesses to understand their digital needs and then work with the development team to deliver solutions. My projects included designing an app for a healthcare startup, the digital transformation of a large state transportation agency, designing an app for an international telecommunications company, and designing an internal tool to allow for faster RFP responses.",
    },
    {
      title: "Kulathinal Lab",
      subtitle: "Undergraduate Researcher",
      timeRange: "2014 - 2015",
      description:
        "Spent several months learning data science techniques and then developed a series of data science and python tutorials targeted at social scientists. Built a website for the lab which sparked my interest in all things digital.",
    },
  ];

  return (
    <main style={{ padding: "1rem 0" }}>
      <div className="container">
        <div className="row mt-4 mb-section">
          <div className="col-12">
            <h1>About Me</h1>
            <p>
              My formal education was all over the place (Political Science ⮕
              Physics ⮕ Biophysics) but it afforded me a deep appreciation for
              the scientific method and also let me pick up skills in coding,
              experimental design, and research.
            </p>
            <p>
              After graduation I took a job as a business analyst with a digital
              consulting firm to try to break into the tech industy, but I always had "code envy" when working with my development team, so I spent my
              evenings learning python and web development.  My role in that first job slowly evolved into a UI/UX designer that also could jump in and help out the development team with the front-end tasks.
            </p>
            <p>
              Ever since then I've worked in roles that let me blend the disciplines of analyst, developer, and designer in order to help my clients create optimal experiences for their users.
            </p>

            <p>
              In my free time I like spending time with my family 👪, playing
              guitar 🎸, trailrunning 🏃‍♂️, gaming 🕹️, and reading books 📘.
            </p>
          </div>
        </div>
        <div className="row mt-4 mb-section">
          <div className="col-12 col-lg-7 pe-0 pe-lg-5 mb-5">
            <h2>Experience</h2>
            {workExperiences.map((experience, index) => (
              <WorkExperience
                title={experience.title}
                subtitle={experience.subtitle}
                timeRange={experience.timeRange}
                description={experience.description}
              />
            ))}
          </div>
          <div className="col-12 col-lg-4 offset-lg-1">
            <div className="mb-5">
              <h3>Design Skills</h3>
              <SkillPillList
                skills={[
                  "Figma",
                  "Sketch",
                  "Adobe XD",
                  "Adobe Creative Suite",
                  "Usability Testing",
                  "User Research",
                  "UX/UI",
                  "Rapid Prototyping",
                  "Responsive Design",
                  "Workshop Facilitation",
                ]}
              />
            </div>
            <div className="mb-5">
              <h3>Development Skills</h3>
              <SkillPillList
                skills={[
                  "HTML",
                  "CSS (LESS, SASS)",
                  "Javascript",
                  "jQuery",
                  "Angular",
                  "React",
                  "Bootstrap",
                  "Node.js",
                  "Python",
                  "Google Colab",
                  "Low-Code (Unqork)",
                ]}
              />
            </div>
            <div className="mb-5">
              <h2>Contact</h2>
              <p>
                If you'd like to get in touch feel free to drop me a message on
                Linkedin.
              </p>
              <a
                href="linkedin.com/in/dansmithb"
                target="_blank"
                className="socialIcon"
              >
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                  <path
                    fill="#0a66c2"
                    d="M416 32H31.9C14.3 32 0 46.5 0 64.3v383.4C0 465.5 14.3 480 31.9 480H416c17.6 0 32-14.5 32-32.3V64.3c0-17.8-14.4-32.3-32-32.3zM135.4 416H69V202.2h66.5V416zm-33.2-243c-21.3 0-38.5-17.3-38.5-38.5S80.9 96 102.2 96c21.2 0 38.5 17.3 38.5 38.5 0 21.3-17.2 38.5-38.5 38.5zm282.1 243h-66.4V312c0-24.8-.5-56.7-34.5-56.7-34.6 0-39.9 27-39.9 54.9V416h-66.4V202.2h63.7v29.2h.9c8.9-16.8 30.6-34.5 62.9-34.5 67.2 0 79.7 44.3 79.7 101.9V416z"
                  />
                </svg>
              </a>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
}
