import { createRoot } from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import App from "./App";
import About from "./routes/About";
import FASite from "./routes/Fasite";
import LoanApplication from "./routes/LoanApplication";
import ThanosSnap from "./routes/ThanosSnap";
import Home from "./routes/Home";
import "./index.css";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/js/bootstrap.bundle";

// After
const container = document.getElementById("root");
const root = createRoot(container);

root.render(
  <BrowserRouter>
    <Routes>
      <Route path="/" element={<App />}>
        <Route index element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/fasite" element={<FASite />} />
        <Route path="/loanapplication" element={<LoanApplication />} />
        <Route path="/thanossnap" element={<ThanosSnap />} />
      </Route>
    </Routes>
  </BrowserRouter>
);
