import { Link } from "react-router-dom";
import "./footer.css";

export default function Footer() {
  return (
    <footer className="bd-footer py-5 dark-footer">
      <div className="container">
        <div className="row">
          <div className="col-lg-4">
            <Link
              className="d-inline-flex align-items-center link-light text-decoration-none"
              to="/"
              aria-label="Dan Smith"
            >
              <span className="fs-5">Dan Smith</span>
            </Link>
            <ul className="list-unstyled small text-muted mb-0">
              <li>Lovingly coded by yours truly. ❤️</li>
            </ul>
          </div>
          <div className="col-6 col-lg-3 offset-lg-1">
            <ul className="list-unstyled d-flex">
              <li className="mb-2 me-3">
                <Link to="/" className="link-light text-decoration-none">
                  Home
                </Link>
              </li>
              <li className="mb-2 me-3">
                <Link to="about" className="link-light text-decoration-none">
                  About
                </Link>
              </li>
              <li className="mb-2 me-3">
                <a
                  href="/#featuredWork"
                  className="link-light text-decoration-none"
                >
                  Work
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </footer>
  );
}
