import React from "react";
import PortfolioPageHeader from "../components/PortfolioPageHeader";
import headerBackground from "../assets/AB_Portfolio.png";

export default function FASite() {
  return (
    <main>
      <PortfolioPageHeader
        title="Advisor Site"
        backgroundColor="var(--orange)"
        backgroundImage={headerBackground}
      />
      <div className="container">
        <div className="row mb-5">
          <div className="col-12 col-md-10 col-lg-8">
            <h2>Overview</h2>
            <p>
              This project was completed for a global asset management firm that
              has over 779 billion USD in assets under management. The client
              hired our firm to do a total redesign of their institutional
              investor website. We conducted extensive user research and then
              used the insights gleaned from that data to design a brand new
              website.
            </p>
          </div>
          <div className="col-12 col-md-12 col-lg-4 mt-4 mt-lg-0 ps-lg-5">
            <h2>Role</h2>
            <div className="project-role">
              <strong>Senior Product Designer</strong>
              <br />
              User Research, Visual Design, Prototyping, Testing
              <br />
              <div className="mt-3">Aug 2021 - Dec 2021</div>
            </div>
          </div>
        </div>
        <div className="row mb-5">
          <div className="col-12 col-md-10 col-lg-8">
            <h2>Background</h2>
            <p>
              This project was broken out into a research phase and a design
              phase. I joined during the second half of the research phase.
            </p>
            <p>
              Our core design team consisted of three senior and two junior
              product designers. While the senior product designers on the team
              had experience in all of the various facets of design, we each had
              a specific specialty that we brought to the table. There was a UX
              researcher, a visual designer, and prototyping and front-end
              development specialist (that was me).
            </p>
            <p>Our team had several key achievements:</p>
            <ul>
              <li>
                <strong>Gained a deep understanding of our users.</strong> Our
                team conducted 30 interviews from which we derived journeys and
                archetypes.
              </li>
              <li>
                <strong>Established a design system.</strong> This up front work
                let us leverage reusable components in Adobe XD to rapidly
                create high-fidelity protoypes.
              </li>
              <li>
                <strong>
                  Redesigned the full institutional investor client website.
                </strong>{" "}
                Over the course of the project our team divided out the workload
                and each took primary ownership over certain pieces.
              </li>
              <li>
                <strong>Confirmed our design satisfied our users needs.</strong>{" "}
                Once we landed on a design we got visual feedback via
                unmoderated testing and then we ran moderated usability testing
                to ensure users could easily carry out tasks the first time they
                see the website.
              </li>
            </ul>
          </div>
        </div>
        <div className="row mb-5">
          <div className="col-12 col-md-10 col-lg-8">
            <h2>User Research</h2>
            <p>
              Our team completed <strong>30 interviews</strong> with financial
              advisors. The interview script included specific questions about
              how the participant conducts their daily job responsibilities, how
              they learn about changes in the financial markets and world
              economy, and how they interact with asset management firms
              websites.
            </p>
          </div>
        </div>
        <div className="row mb-4">
          <div className="col-12 col-md-10 col-lg-8">
            <h2>Compiling our Findings</h2>
            <p>
              We used our research to create four unique user archetypes for
              financial advisors. Each archetype has their own specific goals,
              behaviors, painpoints, and needs that we aimed to address during
              our feature determination stage.
            </p>

            <p>
              The team also created a journey map for how financial advisors
              choose to add a new fund to their portfolio. The user archetypes
              and journey maps were constantly referenced throughout the
              duration of the project.
            </p>
          </div>
        </div>
        <h4 className="typography-work-samples-text">User Archetypes</h4>
        <div className="row">
          <div className="col-12 col-md-6 col-lg-6">
            <div className="screenshots">
              <img
                alt=""
                width="100%"
                src={require("../assets/High_Touch_Enabler.png")}
              />
            </div>
          </div>
          <div className="col-12 col-md-6 col-lg-6">
            <div className="screenshots">
              <img
                alt=""
                width="100%"
                src={require("../assets/High_Touch_Service_Enabler.png")}
              />
            </div>
          </div>
        </div>
        <div className="row mb-4">
          <div className="col-12 col-md-6 col-lg-6">
            <div className="screenshots">
              <img
                alt=""
                width="100%"
                src={require("../assets/Transactional_Service_Provider.png")}
              />
            </div>
          </div>
          <div className="col-12 col-md-6 col-lg-6">
            <div className="screenshots">
              <img
                alt=""
                width="100%"
                src={require("../assets/Evolving_Practioner.png")}
              />
            </div>
          </div>
        </div>
        <h4 className="typography-work-samples-text">User Journey</h4>
        <div className="row mb-5">
          <div className="col-12 col-md-12 col-lg-8">
            <div className="screenshots">
              <img
                alt=""
                width="100%"
                src={require("../assets/FAJourney.png")}
              />
            </div>
          </div>
        </div>

        <div className="row mb-4">
          <div className="col-12 col-md-10 col-lg-8">
            <h2>Feature Determination and Prioritization</h2>
            <p>
              Our team facilitated a brainstorming session with the client where
              we generated a list of features that would satisfy both the needs
              of the business and user archetypes. After that session we grouped
              all of the features into an affinity map and then we ran a feature
              prioritization session to determine what to focus our efforts on
              during the design phase.
            </p>
          </div>
        </div>
        <div className="row mb-5">
          <div className="col-12 col-md-10 col-lg-8">
            <div className="screenshots">
              <img
                alt=""
                width="100%"
                src={require("../assets/ABWebsiteRedesignScopeMap.png")}
                style={{ backgroundColor: "#FAFAFA", padding: "0.75rem" }}
              />
            </div>
          </div>
        </div>
        <div className="row mb-4">
          <div className="col-12 col-md-10 col-lg-8">
            <h2>Building the Design System</h2>
            <p>
              The design system was built using Adobe XD. We spent a lot of time
              up front making sure that the team would have reusable components
              so that we could prototype as efficiently as possible.
            </p>
          </div>
        </div>
        <div className="row mb-5">
          <div className="col-12 col-md-12 col-lg-8">
            <div className="screenshots">
              <img
                alt=""
                width="100%"
                src={require("../assets/design_system.png")}
              />
            </div>
          </div>
        </div>
        <div className="row mb-4">
          <div className="col-12 col-md-10 col-lg-8">
            <h2>Designing the Features</h2>
            <p>
              Our team chose to divide and conquer by features. The two largest
              features I owned were the <strong>search feature</strong> and the{" "}
              <strong>financial product pages</strong>. That being said our team
              was very collaborative and we spent a lot of time helping
              eachother work through design challenges.
            </p>
            <p>The official design process included:</p>
            <ul>
              <li>
                <b>Internal design huddles.</b> On a weekly basis we presented
                our ideas internally to get candid feedback for improvements.
              </li>
              <li>
                <b>Technical reviews.</b> On a weekly basis we engaged the
                client engineering team to ensure technical feasibility and get
                buy in.
              </li>
              <li>
                <b>Executive sponsor presentations.</b> On a biweekly basis we
                presented designs to the larger client group for feedback.
              </li>
              <li>
                <b>Visual design reviews.</b> All designs went through a visual
                review process after the functionality was confirmed.
              </li>
              <li>
                <b>Developer annotations.</b> Each team member created
                annotations to further explain interactions to the development
                team. All annotations were reviewed by another team member
                before considered finalized.
              </li>
            </ul>
          </div>
        </div>
        <div className="row mb-5">
          <div className="col-12 col-md-10 col-lg-8">
            <h4 className="typography-work-samples-text">Sample of Search</h4>
            <img
              alt=""
              width="100%"
              src={require("../assets/search_portolio.png")}
            />
            <h4 className="typography-work-samples-text">
              Sample of Product Page
            </h4>
            <img
              alt=""
              width="100%"
              src={require("../assets/product_page_portfolio.png")}
            />
          </div>
        </div>

        <div className="row mb-5">
          <div className="col-12 col-md-10 col-lg-8">
            <h2>Validating the Designs</h2>
            <p>
              All testing and participant recruiting was handled via
              usertesting.com. For our moderated tests we sent the participants
              a link to our prototype and asking them to share their screen as
              they completed several tasks. For unmoderated testing we had
              participants look at static images of our designs and asked for
              stylistic and copy feedback.
            </p>
            <p>
              We tested all of the new features but put an emphasis on the site
              wide search due to its complexity. The most complex task in our
              script asked users to search for a specific fund and to then use
              the system to email themselves all associated documentation. After
              each task we asked the user to navigate that area of the prototype
              and give us their general impressions.
            </p>
            <p>
              Testing revealed a few minor issues but all required relatively
              easy solutions like changing labels and tweaking colors and
              spacing to create more emphasis on items that didn't catch the
              users eye without further prompting.
            </p>
          </div>
        </div>
        <div className="row mb-section">
          <div className="col-12">
            <h4 className="typography-work-samples-text">Search Prototype</h4>
            <a
              className="blue-text"
              href="https://xd.adobe.com/view/aa58d8b5-5405-4261-a626-6ba0ad03df59-ff0b/"
              target="_blank"
            >
              Open in New Tab
            </a>
            <div className="screenshots mt-2">
              <iframe
                title="Search Prototype"
                width="100%"
                height="700"
                src="https://xd.adobe.com/embed/aa58d8b5-5405-4261-a626-6ba0ad03df59-ff0b/"
                frameBorder="0"
                allowFullScreen
              ></iframe>
            </div>
          </div>
        </div>

        <div className="row mb-5">
          <div className="col-12 col-md-10 col-lg-8">
            <h2>Results</h2>
            <p>
              By the end of this initiative we delivered the designs for the
              brand new website. The team continues to actively provide support
              to the client as their development team implements the designs.
            </p>
            <p>
              I learned a ton from this project as it was the first initiative
              in which I was actively focusing on research for an extended
              period of time. My time on this project served as a good reminder
              of the importance of using research to ensure that the correct
              solution is being designing.
            </p>
          </div>
        </div>
      </div>
    </main>
  );
}
