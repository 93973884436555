import "./portfoliopageheader.css";
import PropTypes from "prop-types";

export default function PortfolioPageHeader({
  title,
  backgroundColor,
  backgroundImage,
}) {
  return (
    <div style={{ backgroundColor: backgroundColor }}>
      <div
        className="container page-header-wrapper"
        style={{
          backgroundImage: `url(${backgroundImage})`,
        }}
      >
        <div className="page-header-content">
          <div className="row">
            <div className="col-12 col-md-8 col-lg-7 d-flex align-items-center">
              <div className="page-header-content-title-wrapper">
                <h1 className="page-header-content-title">{title}</h1>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

PortfolioPageHeader.propTypes = {
  title: PropTypes.string.isRequired,
  backgroundColor: PropTypes.string.isRequired,
  backgroundImage: PropTypes.string.isRequired,
};
