import Button from "../components/Button";
import "./introheader.css";

export default function IntroHeader() {
  return (
    <div className="intro-header mb-section">
      <div className="container">
        <div className="row">
          <div className="col-12 col-md-8 col-lg-7">
            <div className="intro-header-wrapper">
              <div className="intro-header-content">
                <h2 className="intro-header_large-text mb-4">
                  I'm a <span className="orange-text">Designer </span>
                  that <span className="light-blue-text">Codes</span>.
                </h2>
                <p className="intro-header_description-text mb-4">
                  My career has led me to love all aspects of designing and
                  building user centered digital experiences. Currently working
                  in the FinTech industry.
                </p>

                <Button
                  label="See My Work"
                  buttonStyle="filled"
                  variation="primary"
                  isLink={true}
                  linkURL="#featuredWork"
                ></Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
