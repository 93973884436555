import React from "react";
import PortfolioPageHeader from "../components/PortfolioPageHeader";
import headerBackground from "../assets/infinitygaunlet.png";

export default function ThanosSnap() {
  return (
    <main>
      <PortfolioPageHeader
        title="Thanos Snap"
        backgroundColor="var(--purple)"
        backgroundImage={headerBackground}
      />
      <div className="container">
        <div className="row mb-section">
          <div className="col-12 col-md-10 col-lg-8">
            <h2>Background</h2>
            <p>
              I was asked to give a presentation to a design team on how AI is
              being used to expand what is possible within the field of web
              design. I knew that speech recognition and computer vision had
              made massive strides over the past decade and that some of those
              gains were slowly creeping into the browser. I decided to focus in
              on how those two factors could be leveraged within a browser and
              how it might be demonstrated to a group of designers with no
              development or AI experience.
            </p>
            <p>
              A few cool things I had recently came across gave me the idea for
              this project:
            </p>
            <ul>
              <li>
                Google's{" "}
                <a href="https://elgoog.im/thanos/)">Thanos Snap Easter Egg</a>.
              </li>
              <li>
                Google's Tensorflow.js released an API for their BodyPix2.0
                which allows for person detection.
              </li>
              <li>
                Google's Teachable Machine allows novices train a neural network
                to recognize images, sounds, and poses.
              </li>
              <li>
                Daniel Shiffman's book{" "}
                <a href="https://natureofcode.com/">The Nature of Code</a> which
                teaches the reader how to simulate nature in code.
              </li>
            </ul>
          </div>
          <div className="col-12 col-md-12 col-lg-12 col-xl-4 mt-4 mt-xl-0 ps-xl-5">
            <p
              className="large-emphasized-text purple-text"
              style={{ maxWidth: "700px" }}
            >
              I decided to Thanos Snap myself out of my design presentation.
            </p>
          </div>
        </div>

        <div className="row mb-4">
          <div className="col-12 col-md-10 col-lg-8">
            <h2>The Game Plan</h2>
            <p>
              Before jumping into any development work I first like to diagram
              out what I am trying to accomplish from a high level. This larger
              problem can be broken down into three smaller problems: detect a
              person and mask their background, disintegrate an image, and
              detect a snap and use it as a trigger.
            </p>
          </div>
        </div>
        <div className="row mb-section">
          <div className="col-12">
            <img
              src={require("../assets/HighApproach.png")}
              width="100%"
              className="py-4"
              alt="Plan flow diagram"
            />
          </div>
        </div>
        <div className="row mb-4">
          <div className="col-12 col-md-10 col-lg-8">
            <h2>Detecting a Person</h2>
            <p>
              Google's Bodypix library makes it super easy to detect people
              within an image using basic javascript. All of the mathematics and
              technical programming work is hidden behind simple to use
              libraries. In this example I utilized p5.js and ml5.
            </p>
            <p>
              <strong>
                Check out the real-time segmentation below. Visit the codepen if
                you'd like to see how it was accomplished.
              </strong>
            </p>
          </div>
        </div>
        <div className="row mb-section">
          <div className="col-12 col-lg-6">
            <div
              id="original-headshot"
              className="d-flex justify-content-center"
            ></div>
          </div>
          <div className="col-12">
            <iframe
              height="624"
              style={{ width: "100%", height: "624" }}
              scrolling="no"
              title="ImageSegmentation"
              src="https://codepen.io/dbsmith7491/embed/eYyxgMm?default-tab="
              frameBorder="no"
              loading="lazy"
              allowFullScreen={true}
            >
              See the Pen{" "}
              <a href="https://codepen.io/dbsmith7491/pen/eYyxgMm">
                ImageSegmentation
              </a>{" "}
              by Daniel Smith (
              <a href="https://codepen.io/dbsmith7491">@dbsmith7491</a>) on{" "}
              <a href="https://codepen.io">CodePen</a>.
            </iframe>
          </div>
        </div>
        <div className="row mt-4 mb-4">
          <h2>Image Disintegration</h2>
          <div className="col-12 col-md-10 col-lg-8">
            <p>
              Huge shoutout to Daniel Shiffman and his book Nature of Code - it
              strongly influenced how I thought about this problem.
            </p>
            <p>
              If random motion is applied to particles they tend to move further
              away from their starting point over time. I applied this concept
              to the pixels of the image that were part of the set that were
              identified as containing a person. To make it look like the
              particles were floating upwards I restricted the motion in the
              y-axis to only allow upward motion. To make it look like particles
              were fading over time I decreased their opacity with each
              subsequent movement.
            </p>
            <strong>
              Click the button below to see how this looks when applied to a
              static image. Visit the codepen if you'd like to see how it was
              accomplished.
            </strong>
          </div>
        </div>
        <div className="row mb-5">
          <div className="col-12">
            <div className="d-flex flex-column">
              <iframe
                height="687"
                style={{ width: "100%", height: "687px" }}
                scrolling="no"
                title="ImageDisintegration"
                src="https://codepen.io/dbsmith7491/embed/mdpvWXg?default-tab="
                frameBorder="no"
                loading="lazy"
                allowFullScreen={true}
              >
                See the Pen{" "}
                <a href="https://codepen.io/dbsmith7491/pen/mdpvWXg">
                  ImageDisintegration
                </a>{" "}
                by Daniel Smith (
                <a href="https://codepen.io/dbsmith7491">@dbsmith7491</a>) on{" "}
                <a href="https://codepen.io">CodePen</a>.
              </iframe>
            </div>
          </div>
        </div>
        <div className="row mb-section">
          <div className="col-12 col-md-10 col-lg-8">
            <h4 className="typography-small-gray-text">
              Approach for One Pixel
            </h4>
            <p className="mb-5">
              In solving this problem I started with a 3x3 Matrix in which the
              white cells are transparent and the black cell "contains a
              person". Given the restriction of motion the black cell at (1,1)
              can only move to one of the following locations (0,0), (0,1),
              (1,0), (2,1), (2,0). The was determined by passing the current
              (x,y) coordinates to a function that added a random integer
              between 0:1 for y and between -1:1 for x.
            </p>
            <div className="d-flex justify-content-center mb-5">
              <img
                src={require("../assets/Paths.png")}
                width="350px"
                alt="single pixel approach"
              />
            </div>
            <p className="mb-5">
              The next piece was to write a function that could take in (x,y)
              cooridnates and give me the index of the Uint8Array of the image.
              Then using those index values I needed to grab the three values
              following the index value, in the Uint8Index array, since they all
              represent a pixel in RGBA values. After having those values I
              needed to swap the values at those two sets of four index values.
            </p>
            <div className="d-flex justify-content-center mb-5">
              <img
                src={require("../assets/uintswitch.png")}
                width="100%"
                alt="uint manipulation"
              />
            </div>

            <p>
              Once the initial planning was complete it was pretty simple to
              take that same concept and apply it to a loop that goes through
              every pixel in an image, and then draws the new imageData to a
              canvas. That draw function was used when calling an animation
              frame for 100 steps.
            </p>
          </div>
        </div>

        <div className="row">
          <div className="col-12 col-md-10 col-lg-8">
            <h2>Listening for a Snap</h2>
            <p>
              Google's Teachable Machine makes it very easy to train and deploy
              a neural network that can recognize visual or audio patterns. In
              order to create a sound trigger I needed to provide training data
              of the sound of my finger snapping and of background noise. The
              background noise classifier is meant to prevent false positives
              from happening however it isn't totally foolproof.
            </p>
            <p>
              <strong>
                Go ahead and snap your fingers to see the sound classifier
                working in real-time. Visit the codepen if you'd like to see how
                it was accomplished.
              </strong>
            </p>
          </div>
        </div>
        <div className="row mb-5">
          <div className="col-12">
            <iframe
              height="687"
              style={{ width: "100%", height: "687px" }}
              scrolling="no"
              title="Sound Trigger"
              src="https://codepen.io/dbsmith7491/embed/qBpgmoe?default-tab="
              frameBorder="no"
              loading="lazy"
              allowFullScreen={true}
              allow="microphone; camera"
            >
              See the Pen{" "}
              <a href="https://codepen.io/dbsmith7491/pen/qBpgmoe">
                Sound Trigger
              </a>{" "}
              by Daniel Smith (
              <a href="https://codepen.io/dbsmith7491">@dbsmith7491</a>) on{" "}
              <a href="https://codepen.io">CodePen</a>.
            </iframe>
          </div>
        </div>
        <div className="row mb-section">
          <h4 className="typography-small-gray-text">
            Training the name classifiers
          </h4>
          <div className="col-12   mb-5 bg-light p-3 pt-4 pb-4">
            <img
              src={require("../assets/teachablemachine.withgoogle.com_train_audio.png")}
              width="100%"
              alt="Teachable Machine"
            />
          </div>
        </div>
        <div className="row mb-4">
          <div className="col-12 col-md-10 col-lg-8">
            <h2>Bringing it all Together</h2>
            <p>
              After figuring out all of the pieces it became an exercise of
              stitching it all together.
            </p>
            <p>
              <strong>
                Go ahead and get out of the picture frame and snap a background
                image. Then when you snap you can make yourself disapear!
              </strong>
            </p>
          </div>
        </div>
        <div className="row mb-section">
          <div className="col-12">
            <iframe
              height="776"
              style={{ width: "100%", height: "776" }}
              scrolling="no"
              title="ThanosSnapV3"
              src="https://codepen.io/dbsmith7491/embed/QWaYMbL?default-tab="
              frameborder="no"
              loading="lazy"
              allowFullScreen={true}
              allow="microphone; camera"
            >
              See the Pen{" "}
              <a href="https://codepen.io/dbsmith7491/pen/QWaYMbL">
                ThanosSnapV3
              </a>{" "}
              by Daniel Smith (
              <a href="https://codepen.io/dbsmith7491">@dbsmith7491</a>) on{" "}
              <a href="https://codepen.io">CodePen</a>.
            </iframe>
          </div>
        </div>
      </div>
    </main>
  );
}
