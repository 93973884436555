import FeaturedWorkSection from "../components/FeaturedWorkSection";
import IntroHeader from "../components/IntroHeader";

export default function Home() {
  return (
    <main>
      <IntroHeader></IntroHeader>
      <FeaturedWorkSection></FeaturedWorkSection>
    </main>
  );
}
