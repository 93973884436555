import "./problemsolutioncard.css";
import PropTypes from "prop-types";

function ProblemSolutionCard({ variant, description }) {
  var cardClassesArr = ["card-problem-solution", "alert"];
  if (variant === "solution") {
    cardClassesArr.push("alert-success");
  } else {
    cardClassesArr.push("alert-danger");
  }
  let cardClasses = cardClassesArr.join(" ");

  return (
    <div className={cardClasses}>
      <h3 className="card-problem-solution-title">
        {variant === "solution" ? "Solution" : "Problem"}
      </h3>
      <p className="card-problem-solution-description">{description}</p>
    </div>
  );
}
ProblemSolutionCard.propTypes = {
  variant: PropTypes.oneOf(["problem", "solution"]).isRequired,
  description: PropTypes.string.isRequired,
};

export default ProblemSolutionCard;
