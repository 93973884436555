import "./workexperience.css";
import PropTypes from "prop-types";

function WorkExperience({ title, subtitle, timeRange, description }) {
  return (
    <div className="card card-about-me-work-experience">
      <div className="card-body">
        <div className="d-flex align-items-center justify-content-between">
          <h4 className="card-title">{title}</h4>
          <h5 className="card-subtitle">{timeRange}</h5>
        </div>

        <h5 className="card-subtitle mb-2 text-muted">{subtitle}</h5>
        <p className="card-text">{description}</p>
      </div>
    </div>
  );
}
WorkExperience.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  timeRange: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
};

export default WorkExperience;
