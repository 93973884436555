import { Link } from "react-router-dom";
import "./featuredworkcard.css";
import PropTypes from "prop-types";

function FeaturedWorkCard({
  title,
  description,
  image,
  bgColor,
  textColor = "#FFFFFF",
  goTo,
}) {
  const imageStyle = {
    backgroundImage: "url(" + image + ")",
    backgroundColor: bgColor,
    color: textColor,
  };
  return (
    <Link to={goTo} className="card card-work-experience" style={imageStyle}>
      <div className="row">
        <div className="col-12 col-lg-6">
          <div className="card-body text-center text-lg-start">
            <h5 className="card-title">{title}</h5>
            <p className="card-text">{description}</p>
          </div>
        </div>
      </div>
    </Link>
  );
}

FeaturedWorkCard.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  bgColor: PropTypes.string.isRequired,
  goTo: PropTypes.string.isRequired,
  textColor: PropTypes.string.isRequired,
};

export default FeaturedWorkCard;
