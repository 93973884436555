import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import "./button.css";

/**
 * Primary UI component for user interaction
 */
export const Button = ({
  variation,
  buttonStyle,
  size,
  label,
  isLink,
  linkURL,
}) => {
  var classArr = ["btn"];

  //Push variation style into the array
  if (buttonStyle === "outline") {
    classArr.push(`btn-outline-${variation}`);
  } else {
    classArr.push(`btn-${variation}`);
  }

  //Push the size into the class list if not regular
  if (size !== "rg") {
    classArr.push(`btn-${size}`);
  }

  if (!isLink) {
    return (
      <button type="button" className={classArr.join(" ")}>
        {label}
      </button>
    );
  } else {
    return (
      <Link type="button" className={classArr.join(" ")} to={linkURL}>
        {label}
      </Link>
    );
  }
};

Button.propTypes = {
  isLink: PropTypes.bool,
  linkURL: PropTypes.string,
  variation: PropTypes.oneOf([
    "primary",
    "secondary",
    "success",
    "danger",
    "warning",
    "info",
    "light",
    "dark",
    "link",
  ]).isRequired,
  /**
   * Is this the principal call to action on the page?
   */
  buttonStyle: PropTypes.oneOf(["filled", "outline"]).isRequired,
  /**
   * How large should the button be?
   */
  size: PropTypes.oneOf(["sm", "rg", "lg"]),
  /**
   * Button contents
   */
  label: PropTypes.string.isRequired,
  /**
   * Optional click handler
   */
  onClick: PropTypes.func,
};

Button.defaultProps = {
  variation: "primary",
  buttonStyle: "filled",
  size: "rg",
  onClick: undefined,
  isLink: false,
  linkURL: undefined,
};

export default Button;
